var LayoutModulesConfiguration =
{
    translations : {
        nomodules : null
    },
    modulesAddUrl: null,
    ckEditorCss: null,

    init : function(config)
    {
        this.translations = config.translations;
        this.modulesAddUrl = config.modulesAddUrl;
        this.ckEditorCss = config.ckEditorCss;

        this.initSelectModules();
        this.initSortable();
        this.initDeleteModule();
        this.initMoveUp();
        this.initMoveDown();
    },

    initSelectModules : function()
    {
        var self = this;
        $('.layout-modules .select-modules').on('change', function () {
            var $layoutModulesContainer = $(this).parents('.layout-modules').first();
            var $selectModules = $(this);

            if ($selectModules.val())
            {
                $.ajax(
                {
                    type: "POST",
                    url: self.modulesAddUrl,
                    data: {
                        name: $layoutModulesContainer.find('.layout-module-name').val(),
                        index: $layoutModulesContainer.find('.modules-number').val(),
                        type: $selectModules.val()
                    },
                    success: function (data) {
                        $layoutModulesContainer.find('.modules .no-modules').remove();
                        $layoutModulesContainer.find('.modules').append(data);
                        $selectModules.val('');

                        self.reindex($layoutModulesContainer);
                        self.initCKEditor($layoutModulesContainer.find('.modules .module').last().find('textarea.ckeditor'));
                    },
                    dataType: 'html'
                });
            }
        });
    },

    initCKEditor : function($selector)
    {
      var txtArray = $selector.get();
      for(var i = 0; i < txtArray.length; i++) {
        if(this.ckEditorCss != null) {
            CKEDITOR.replace(txtArray[i], {contentsCss: this.ckEditorCss });
        }
        else {
             CKEDITOR.replace(txtArray[i]);
        }
      }  
      /*if(this.ckEditorCss != null) {
        $selector.ckeditor({
          contentsCss: this.ckEditorCss
        });
      }
      else {
        $selector.ckeditor();
      }*/
    },

    initSortable : function()
    {
        var self = this;
        $('.layout-modules.configuration .modules').sortable({
            handle: ".module-header",
            cancel: ".module-header .action",
            placeholder: "layout-module-placeholder ui-corner-all",
            update: function (event, ui) {
                self.reindex(ui.item.parents('.layout-modules').first());
            },
            start: function (e, ui) {
                ui.placeholder.height(ui.helper.outerHeight());
                //self.destroyWysihtml5($(ui.item));
            },
            stop: function (e, ui) {
               self.initCKEditor($(ui.item).find('textarea.ckeditor'));
            }
        });
    },

    initDeleteModule : function()
    {
        var self = this;
        $(document).on('click', '.layout-modules .module-header .delete', function () {
            var $layoutModulesContainer = $(this).parents('.layout-modules').first();
            var $deletedModules = $layoutModulesContainer.find('.deleted-modules').first();
            var deletedModules = $deletedModules.val();
            var $module = $(this).parents('.module').first();
            var moduleId = $module.find('.module-id').val();

            if(moduleId)
            {
                deletedModules += ',' + moduleId;

                while(deletedModules.charAt(0) === ',')
                {
					deletedModules = deletedModules.substr(1);
				}

                $deletedModules.val(deletedModules);
            }

            $module.remove();

			if(self.reindex($layoutModulesContainer) == 0)
            {
                $layoutModulesContainer.find('.modules').html('<div class="no-modules">' + self.translations.nomodules + '</div>');
            }
        });
    },

    initMoveUp : function()
    {
        var self = this;
        $(document).on('click', '.layout-modules .module-header .up', function () {
            var $layoutModulesContainer = $(this).parents('.layout-modules').first();
            var $module = $(this).parents('.module').first();

            self.destroyCKEditor($module);
            $module.prev().before($module);
            self.reindex($layoutModulesContainer);
            self.initCKEditor($module.find('textarea.ckeditor'));
        });
    },

    initMoveDown : function()
    {
        var self = this;
        $(document).on('click', '.layout-modules .module-header .down', function () {
            var $layoutModulesContainer = $(this).parents('.layout-modules').first();
            var $module = $(this).parents('.module').first();

            self.destroyCKEditor($module);
            $module.next().after($module);
            self.reindex($layoutModulesContainer);
            self.initCKEditor($module.find('textarea.ckeditor'));
        });
    },

    destroyCKEditor : function($module)
    {
		$module.find('textarea.ckeditor').each(function() {
			var editor = $(this).ckeditor().editor;

			editor.updateElement();
			editor.destroy();
		});
    },

    reindex : function($layoutModulesContainer)
    {
        var modulesNumber = 0;

        $layoutModulesContainer.find('.modules .module').each(function(index)
        {
            var $module = $(this);

            $module.find('.indexable').each(function()
            {
                var $indexable = $(this);
                var indexableName = $indexable.attr('name');

                $indexable.attr('name', indexableName.replace(/_\d+_/, '_' + index + '_'));
            });

            modulesNumber++;
        });

        $layoutModulesContainer.find('.modules-number').val(modulesNumber);

        return modulesNumber;
    }
}
