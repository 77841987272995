var Tbg =
{
    delayTimer : 0,

    init : function(ckeditorBaseCss) {
        this.initDatePicker();
        this.initFlexSlider();
        this.initAjax();
        this.initFileUpload();
        this.initWysihtml5();
        this.initPopovers();
		this.initCKEditor(ckeditorBaseCss);
    },

    initDatePicker : function() {
        $( ".datepicker" ).datepicker({ dateFormat: "yy-mm-dd" });
    },

    initWysihtml5 : function() {
        $('.wysihtml5').wysihtml5({
            link: false,
            image: false,
            lists: true,
            blockquote : false,
            small: false,
            stylesheets: ['/css/wysihtml5.css']
        });
    },

    initFlexSlider : function() {
        $(document).ready(function (){
            $('.flexslider').flexslider({
                prevText: "",
                nextText: "",
                pauseOnHover: true,
                video: true
            });
        });
    },

    initAjax : function() {
    	$.ajaxSetup({
	        headers: {
	            'X-CSRF-Token': $('meta[name="_token"]').attr('content')
	        }
    	});
    },

    initFileUpload : function() {

        $(document).on('change', '.btn-file :file', function() {
            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
        });

        $(document).on('fileselect', '.btn-file :file', function(event, numFiles, label) {
            var input = $(this).closest('.input-group').find(':text'),
                log = numFiles > 1 ? numFiles + ' files selected' : label;

            if( input.length ) {
                input.val(log);
            } else {
                if( log ) alert(log);
            }
        });
    },

    initPopovers : function() {

        $(function () {
            $('[data-toggle="popover"]').popover({
                html : true,
                container: 'body'
            });
        })
    },

    gridSearch : function(dataTable, query) {
        clearTimeout(Tbg.delayTimer);
        Tbg.delayTimer = setTimeout(function() {
            dataTable.fnFilter( query );
        }, 200);
    },
	
	initCKEditor : function(ckeditorBaseCss) {
        var txtArray = $('.ckeditor').get();
      for(var i = 0; i < txtArray.length; i++) {
        if(ckeditorBaseCss != null) {
            CKEDITOR.replace(txtArray[i], { contentsCss: ckeditorBaseCss });
        }
        else {
             CKEDITOR.replace(txtArray[i]);
        }
      }  
	},
}